<section class="customer-list-wrapper">
    <div class="">
        <div class="row">
            <form [formGroup]="newEnqiryCreate" (ngSubmit)="saveEnquiry(newEnqiryCreate)">
                <div class="fixheader">
                    <!-- Customer List Header -->
                    <div class="row">
                        <div class="col-md-5 col-12">
                            <div class="d-flex justify-content-between align-items-center m-1">
                                <div class="d-flex align-items-center">
                                    <h1 class=""><b>New Enquiry</b></h1>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-md-7 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2">
                            <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100 mr-left-30">
                                <div
                                    class="col-10 col-md-12 mt-2 ml-1 ml-md-0 mt-md-0 d-flex justify-content-start justify-content-md-end">
                                    <button type="button" class="btn btn-outline-danger mr-2 cursor-pointer"
                                        rippleEffect (click)="cancelPreview()">
                                        Cancel
                                    </button>
                                    <button class="btn btn-success" type="submit" rippleEffect>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="f-preview-overview-content-new">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="overviewsection">
                                <div class="mb-2">
                                    <!-- <app-customer-create-basic-detail
                                                          (customerBasicDetailChild)="customerBasicDetailChild($event)"></app-customer-create-basic-detail> -->
                                    <!-- Accordion Basic start -->
                                    <div class="rounded-corner-accordian">
                                        <section id="accordion">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div id="accordionWrapa1" role="tablist"
                                                        aria-multiselectable="true">
                                                        <div class="collapse-icon p-1">
                                                            <div class="collapse-default">

                                                                <div class="card">
                                                                    <div class="card-header">

                                                                        <h6><i class="feather icon-list"></i>&nbsp;Basic
                                                                            Details</h6>
                                                                    </div>
                                                                    <div class="row p-1">

                                                                        <input type="hidden" formControlName="EnquiryId" value="enquiryId"/>
                                                                        <input type="hidden" formControlName="CompanyOrLeadId"/>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Customer Status</label>
                                                                                <ng-select id="CustomerStatus"
                                                                                [ngClass]="{
                                                                                    'ng-touched':
                                                                                    newEnqiryCreate.get(
                                                                                        'CustomerStatus'
                                                                                      ).touched,
                                                                                    error:
                                                                                    newEnqiryCreate.get(
                                                                                        'CustomerStatus'
                                                                                      ).invalid &&
                                                                                      newEnqiryCreate.get(
                                                                                        'CustomerStatus'
                                                                                      ).touched
                                                                                  }"
                                                                                formControlName="CustomerStatus"
                                                                                [items]="customerStatusOptions" bindValue="label"
                                                                                bindLabel="label"
                                                                                [readonly]="disable" 
                                                                                (change)=onChangeStatus($event)
                                                                                placeholder="Lead / Customer">
                                                                                
                                                                                </ng-select>
                                                                                <div class="invalid-feedback" *ngIf="newEnqiryCreate.get('CustomerStatus').invalid && newEnqiryCreate.get('CustomerStatus').touched">
                                                                                    Please select customer status
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-12">
                                                                            <div class="form-group" *ngIf="this.CustomerStatus == 'Customer'">
                                                                              <label> Company Name</label>
                                                                              <div class="form-group f-autocomplet-wrapper">
                                                                                <ng-select id="CompanyId" formControlName="CompanyId" [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'CompanyId'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'CompanyId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'CompanyId'
                                                                                          ).touched
                                                                                      }" bindValue="companyId" bindLabel="companyName" [readonly]="disable" 
                                                                                      (change)="onChangeCompany($event)"
                                                                                      [clearable]="false" placeholder="Select Company"  [items]="companies" #select>
                                                                                  <ng-option *ngIf="
                                                                                          companies.length === 0
                                                                                        " [disabled]="true">No data</ng-option>
                                                                                  <!-- <ng-option *ngFor="
                                                                                          let comp of companies
                                                                                        " [value]="comp.companyId">
                                                                                    {{ comp.companyName }}
                                                                                  </ng-option> -->
                                                                                  <ng-option [value]="null" disabled>select data</ng-option>
                                                                                </ng-select>
                                                                                <span *ngIf="
                                                                                        newEnqiryCreate.get(
                                                                                          'CompanyId'
                                                                                        ).invalid &&
                                                                                        newEnqiryCreate.get(
                                                                                          'CompanyId'
                                                                                        ).touched
                                                                                      " class="invalid-form">
                                                                                  <small class="form-text text-danger">Please Select 
                                                                                    Company</small>
                                                                                </span>
                                                                                <input type="hidden" formControlName="CompanyName"/>
                                                                              </div>
                                                                            </div>
                                                                            <div class="form-group" *ngIf="this.CustomerStatus == 'Lead'">
                                                                                <label> Lead Name</label>
                                                                                <div class="form-group f-autocomplet-wrapper">
                                                                                  <ng-select id="LeadId" formControlName="LeadId" [ngClass]="{
                                                                                          'ng-touched':
                                                                                          newEnqiryCreate.get(
                                                                                              'LeadId'
                                                                                            ).touched,
                                                                                          error:
                                                                                          newEnqiryCreate.get(
                                                                                              'LeadId'
                                                                                            ).invalid &&
                                                                                            newEnqiryCreate.get(
                                                                                              'LeadId'
                                                                                            ).touched
                                                                                        }" bindValue="leadId" bindLabel="companyName" [readonly]="disable" 
                                                                                        (change)="onChangeLeadName($event)"
                                                                                    placeholder="Select Lead" [items]="leaddata" #select>
                                                                                    <ng-option *ngIf="
                                                                                    leaddata.length === 0
                                                                                          " [disabled]="true">No data</ng-option>
                                                                                    <ng-option *ngFor="
                                                                                            let comp of leaddata
                                                                                          " [value]="comp.leadId">
                                                                                      {{ comp.companyName }}
                                                                                    </ng-option>
                                                                                  </ng-select>
                                                                                  <span *ngIf="
                                                                                          newEnqiryCreate.get(
                                                                                            'LeadId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'LeadId'
                                                                                          ).touched
                                                                                        " class="invalid-form">
                                                                                    <small class="form-text text-danger">Please Select 
                                                                                      Lead</small>
                                                                                  </span>
                                                                                  <input type="hidden" formControlName="LeadName"/>
                                                                                </div>
                                                                              </div>
                                                                          </div>


                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Lead Owner (Sales
                                                                                    Person)</label>
                                                                                <ng-select id="salesPerson"
                                                                                    formControlName="LeadOwnerId"
                                                                                    [items]="salesPerson" bindValue="userId" [readonly]="disable" 
                                                                                    bindLabel="userDisplayName"
                                                                                    placeholder="Sales Person Name"
                                                                                    [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'LeadOwnerId'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'LeadOwnerId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'LeadOwnerId'
                                                                                          ).touched
                                                                                      }">
                                                                                    <ng-option></ng-option>
                                                                                </ng-select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label>Service Type</label>
                                                                                <ng-select id="serviceType"
                                                                                    formControlName="ServiceTypeId"
                                                                                    [items]="serviceTypes" 
                                                                                    bindLabel="jobtypeName"
                                                                                    bindValue="jobtypeId" 
                                                                                    (change)="onChangeServiceType($event)"
                                                                                    placeholder="FCL, Air, Etc" [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'ServiceTypeId'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'ServiceTypeId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'ServiceTypeId'
                                                                                          ).touched
                                                                                      }">
                                                                                    <ng-option></ng-option>
                                                                                </ng-select>
                                                                                <span *ngIf="
                                                                                        newEnqiryCreate.get(
                                                                                        'ServiceTypeId'
                                                                                        ).invalid &&
                                                                                        newEnqiryCreate.get(
                                                                                        'ServiceTypeId'
                                                                                        ).touched
                                                                                    " class="invalid-form">
                                                                                <small class="form-text text-danger">Please Select Service Type </small>
                                                                                </span>
                                                                               
                                                                                <input type="hidden" formControlName="ServiceType"/>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label>Inco Terms</label>
                                                                                <ng-select id="incoTerms"
                                                                                    formControlName="IncoTermsId"
                                                                                    [items]="incoTerm" 
                                                                                    bindLabel="incotermName"
                                                                                    bindValue="incoTermId" 
                                                                                    (change)="onChangeIncoTerm($event)"
                                                                                    placeholder="Exworks, FOB, etc" [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'IncoTermsId'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'IncoTermsId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'IncoTermsId'
                                                                                          ).touched
                                                                                      }">
                                                                                    <ng-option></ng-option>
                                                                                </ng-select>
                                                                                <span *ngIf="
                                                                                        newEnqiryCreate.get(
                                                                                        'IncoTermsId'
                                                                                        ).invalid &&
                                                                                        newEnqiryCreate.get(
                                                                                        'IncoTermsId'
                                                                                        ).touched
                                                                                    " class="invalid-form">
                                                                                <small class="form-text text-danger">Please Select Inco Term </small>
                                                                                </span>
                                                                                
                                                                                <input type="hidden" formControlName="IncoTerms"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>POL</label>
                                                                                <ng-select id="POLId"
                                                                                    formControlName="POLId"
                                                                                    [items]="locationMasterData"
                                                                                    bindValue="locationId"
                                                                                    bindLabel="locationShortName"
                                                                                    (change)="onChangePOL($event);onChangePOLPOD()"
                                                                                    placeholder="Nava Sheva, Mumbai,etc"[ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'POLId'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'POLId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'POLId'
                                                                                          ).touched
                                                                                      }">
                                                                                    <ng-option></ng-option>
                                                                                </ng-select>
                                                                                <span *ngIf="
                                                                                    newEnqiryCreate.get(
                                                                                    'POLId'
                                                                                    ).invalid &&
                                                                                    newEnqiryCreate.get(
                                                                                    'POLId'
                                                                                    ).touched
                                                                                " class="invalid-form">
                                                                            <small class="form-text text-danger">Please Select POL </small>
                                                                            </span>
                                                                                <input type="hidden" formControlName="POL"/>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>POD</label>
                                                                                <ng-select id="PODId"
                                                                                    formControlName="PODId"
                                                                                    [items]="locationMasterData"
                                                                                    bindValue="locationId"
                                                                                    bindLabel="locationShortName"
                                                                                    (change)="onChangePOD($event);onChangePOLPOD()"
                                                                                    placeholder="Istambul, New York, etc" [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'PODId'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'PODId'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'PODId'
                                                                                          ).touched
                                                                                      }">
                                                                                    <ng-option></ng-option>
                                                                                </ng-select>
                                                                                <span *ngIf="
                                                                                    newEnqiryCreate.get(
                                                                                    'PODId'
                                                                                    ).invalid &&
                                                                                    newEnqiryCreate.get(
                                                                                    'PODId'
                                                                                    ).touched
                                                                                " class="invalid-form">
                                                                            <small class="form-text text-danger">Please Select POD </small>
                                                                            </span>
                                                                                <input type="hidden" formControlName="POD"/>

                                                                            </div>
                                                                            <!-- Show error for same POL and POD -->
                                                                            <span *ngIf="newEnqiryCreate.hasError('samePOLPOD')">
                                                                                <span class="invalid-form">
                                                                                <small class="form-text text-danger">POL and POD should not be the same</small>
                                                                                </span>
                                                                            </span>
                                                                        </div>

                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Commodity</label>
                                                                                <input id="Commodity" type="text"
                                                                                    class="form-control"
                                                                                    formControlName="Commodity"
                                                                                    placeholder="Auto Parts, Plastics,etc" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label>Free Time - Origin</label>
                                                                                <input id="FreeTimeOrigin" type="text"
                                                                                    class="form-control"
                                                                                    formControlName="FreeTimeOrigin"
                                                                                    placeholder="Auto Parts, Plastics,etc" [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'FreeTimeOrigin'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'FreeTimeOrigin'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'FreeTimeOrigin'
                                                                                          ).touched
                                                                                      }"/>
                                                                                      <span *ngIf="
                                                                                      newEnqiryCreate.get(
                                                                                        'FreeTimeOrigin'
                                                                                      ).invalid &&
                                                                                      newEnqiryCreate.get(
                                                                                        'FreeTimeOrigin'
                                                                                      ).touched
                                                                                    " class="invalid-form">
                                                                                        <small class="form-text text-danger">Please Select Free time origin
                                                                                          Count</small>
                                                                                      </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label>Free Time - Destination</label>
                                                                                <input id="FreeTimeDestination"
                                                                                    type="text" class="form-control"
                                                                                    formControlName="FreeTimeDestination"
                                                                                    placeholder="Auto Parts, Plastics,etc" [ngClass]="{
                                                                                        'ng-touched':
                                                                                        newEnqiryCreate.get(
                                                                                            'FreeTimeDestination'
                                                                                          ).touched,
                                                                                        error:
                                                                                        newEnqiryCreate.get(
                                                                                            'FreeTimeDestination'
                                                                                          ).invalid &&
                                                                                          newEnqiryCreate.get(
                                                                                            'FreeTimeDestination'
                                                                                          ).touched
                                                                                      }"/>
                                                                                      <span *ngIf="
                                                                                      newEnqiryCreate.get(
                                                                                        'FreeTimeDestination'
                                                                                      ).invalid &&
                                                                                      newEnqiryCreate.get(
                                                                                        'FreeTimeDestination'
                                                                                      ).touched
                                                                                    " class="invalid-form">
                                                                                        <small class="form-text text-danger">Please Select Free Time Destination</small>
                                                                                      </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Notes</label>
                                                                                <textarea id="Note" type="text"
                                                                                    class="form-control"
                                                                                    formControlName="note"
                                                                                    placeholder="General & Stackable"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <!-- Accordion Basic end -->
                                </div>

                            </div>

                        </div>
                        <div class="col-md-6" >
                            <div class="rounded-corner-accordian" *ngIf="!['LCL', 'AIR'].includes(selectedServiceType)">
                                <!-- <div class="rounded-corner-accordian" > -->
                                <section id="accordion">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
                                                <div class="collapse-icon p-1">
                                                    <div class="collapse-default">
                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="false">
                                                            <ngb-panel id="panelBorderBottom1">
                                                                <ng-template ngbPanelTitle>
                                                                    <div class="panel-title pb-2">
                                                                        <div
                                                                            class="d-flex justify-content-start align-items-center">
                                                                            <span class="icon-left">
                                                                                <i class="feather icon-file"
                                                                                    aria-hidden="true"></i>
                                                                            </span>
                                                                            <span class="padd-add">Required Equipment
                                                                                (FCL)</span>
                                                                        </div>
                                                                        <div class="d-flex ">
                                                                            <!-- <button
                                                                type="button"
                                                                class="btn btn-outline-success mr-2 f-adrress-btn"
                                                                
                                                                rippleEffect
                                                                (click)="toggleSideNav()"
                                                              >
                                                                Add Address
                                                              </button>
                                                          <button type="button" class="btn btn-outline-success mr-2 f-adrress-btn"
                                                            rippleEffect >
                                                            Add Address
                                                          </button> -->
                                                                            <span class="icon-right">

                                                                                <i class="feather icon-plus-square" (click)="openRequiredEquipmentModal(enquiryId)"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>

                                                                <ng-template ngbPanelContent>
                                                                    <div *ngIf="equipDetailsList && equipDetailsList.length === 0">
                                                                        <input formControlName="equipment" type="hidden" [value]="equipDetailsList"/>
                                                                            <div class="row pt-1">
                                                                                <div class="col-md-6 text-danger">
                                                                                
                                                                                    <p>
                                                                                    Please Enter Atleast one Equipment Details
                                                                                    </p>
                                                                                </div>
                                                                                <div class="col-md-6 text-danger">
                                                                                    <p class="text-right">!</p>
                                                                                </div>
                                                                            </div>
                                                                    </div>  
                                                                    <div *ngIf="equipDetailsList">
                                                                        <div *ngIf="equipDetailsList && equipDetailsList.length > 0" class="table-responsive">
                                                                         
                                                                         
                                                                            <table class="table table-responsive table-hover table-striped table-bordered" style="padding: 10px;">
                                                                                <thead class="thead-light">
                                                                                  <tr>
                                                                                    <th style="padding: 10px;">Container Type</th>
                                                                                    <th style="padding: 10px;">Container Count</th>
                                                                                    <th style="padding: 10px;">Gross Weight</th>
                                                                                    <th style="padding: 10px;">Edit</th>
                                                                                    <th style="padding: 10px;">Delete</th>
                                                                                  </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                  <tr *ngFor="let detail of equipDetailsList" >
                                                                                    <td style="padding: 10px;">{{ detail.containerTypes }}{{detail.containerType}}</td>
                                                                                    <td style="padding: 10px;">{{ detail.containerCount}}</td>
                                                                                    <td style="padding: 10px;">{{ detail.grossWeight}}</td>
                                                                                    <td style="padding: 10px;"  class="cursor-pointer" (click)="openModalContainerByData(detail)">edit</td>
                                                                                    <td style="padding: 10px;"  class="cursor-pointer">
                                                                                        <a (click)="deleteContainerByID(detail.enquiryContainerId)" href="javascript:void(0)" ngbDropdownItem>
                                                                                        <i data-feather="trash" class="mr-1 text-danger"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                </tbody>
                                                                              </table>
                                                                          
                                                                        </div>
                                                                      </div>

                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="rounded-corner-accordian" *ngIf="selectedServiceType === 'LCL' || selectedServiceType === 'AIR'">
                                <!-- <div class="rounded-corner-accordian"> -->
                                <section id="accordion">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
                                                <div class="collapse-icon p-1">
                                                    <div class="collapse-default">
                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="false">
                                                            <ngb-panel id="panelBorderBottom2">
                                                                <ng-template ngbPanelTitle>
                                                                    <div class="panel-title pb-2">
                                                                        <div
                                                                            class="d-flex justify-content-start align-items-center">
                                                                            <span class="icon-left">
                                                                                <i class="feather icon-file"
                                                                                    aria-hidden="true"></i>
                                                                            </span>
                                                                            <span class="padd-add">Cargo Weight &
                                                                                Dimensions (Air & LCL)</span>
                                                                        </div>
                                                                        <div class="d-flex ">
                                                                            <!-- <button
                                                                type="button"
                                                                class="btn btn-outline-success mr-2 f-adrress-btn"
                                                                
                                                                rippleEffect
                                                                (click)="toggleSideNav()"
                                                              >
                                                                Add Address
                                                              </button>
                                                          <button type="button" class="btn btn-outline-success mr-2 f-adrress-btn"
                                                            rippleEffect >
                                                            Add Address
                                                          </button> -->
                                                                            <span class="icon-right">

                                                                                <i class="feather icon-plus-square" (click)="openPackageDetailModal(enquiryId)"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>

                                                                <ng-template ngbPanelContent>
                                                                    <div *ngIf="pakagesDetailsList && pakagesDetailsList.length === 0">
                                                                        <input formControlName="pakage" type="hidden" [value]="pakagesDetailsList"/>
                                                                            <div class="row pt-1">
                                                                            <div class="col-md-6 text-danger">
                                                                            
                                                                                <p>
                                                                                Please Enter Atleast one Pakage Details
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-md-6 text-danger">
                                                                                <p class="text-right">!</p>
                                                                            </div>
                                                                            </div>
                                                                    </div>  
                                                                    <div *ngIf="pakagesDetailsList">
                                                                        <div *ngIf="pakagesDetailsList && pakagesDetailsList.length > 0" class="table-responsive">
                                                                         
                                                                         
                                                                            <table class="table table-responsive table-hover table-striped table-bordered" style="width: 100%; table-layout: auto;font-size: 13px;">
                                                                                <thead class="thead-light">
                                                                                  <tr style="font-size: 13px;">
                                                                                    <th style="padding: 10px;">Package Type</th>
                                                                                    <th style="padding: 10px;">Package Count</th>
                                                                                    <th style="padding: 10px;">Length (mm)</th>
                                                                                    <th style="padding: 10px;">Weight (mm)</th>
                                                                                    <th style="padding: 10px;">Height (mm)</th>
                                                                                    <th style="padding: 10px;">Net Weight</th>
                                                                                    <th style="padding: 10px;">Gross Weight</th>
                                                                                    <th style="padding: 10px;">Volume Weight</th>
                                                                                    <th style="padding: 10px;">CBM</th>
                                                                                    <th style="padding: 10px;">Edit</th>
                                                                                    <th style="padding: 10px;">Delete</th>
                                                                                  </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                  <tr *ngFor="let detail of pakagesDetailsList" >
                                                                                    <td style="padding: 10px;">{{ detail.packageType }}</td>
                                                                                    <td style="padding: 10px;">{{ detail.packageCount}}</td>
                                                                                    <td style="padding: 10px;">{{ detail.lengthMm }}</td>
                                                                                    <td style="padding: 10px;">{{ detail.weightMm }}</td>
                                                                                    <td style="padding: 10px;">{{ detail.heightMm }}</td>
                                                                                    <td style="padding: 10px;">{{ detail.netWeight }}</td>
                                                                                    <td style="padding: 10px;">{{ detail.grossWeight}}</td>
                                                                                    <td style="padding: 10px;">{{ detail.volumeWeight}}</td>
                                                                                    <td style="padding: 10px;">{{ detail.cbm}}</td>
                                                                                    <td style="padding: 10px;" (click)="openModalPakagesByData(detail)" class="cursor-pointer">Edit</td>
                                                                                    <td style="padding: 10px;" class="cursor-pointer">
                                                                                        <a (click)="deletePakagesId(detail.enquiryPackageId)" href="javascript:void(0)" ngbDropdownItem>
                                                                                            <i data-feather="trash" class="mr-1"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                  </tr>
                                                                                </tbody>
                                                                              </table>
                                                                          
                                                                        </div>
                                                                      </div>
                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="rounded-corner-accordian mt-2 ">
                                <section id="accordion">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
                                                <div class="collapse-icon p-1">
                                                    <div class="collapse-default">
                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="false">
                                                            <ngb-panel id="panelBorderBottom3">
                                                                <ng-template ngbPanelTitle>
                                                                    <div class="panel-title pb-2">
                                                                        <div
                                                                            class="d-flex justify-content-start align-items-center">
                                                                            <span class="icon-left">
                                                                                <i class="feather icon-file"
                                                                                    aria-hidden="true"></i>
                                                                            </span>
                                                                            <span class="padd-add">Hazardous Cargo Details</span>
                                                                        </div>
                                                                        <div class="d-flex ">
                                                                          
                                                                            <span class="icon-right">

                                                                                <i class="feather icon-plus-square"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>

                                                                <ng-template ngbPanelContent>
                                                                    <!-- <div *ngIf="addrDetailsList && addrDetailsList.length === 0">
                                                                        <input formControlName="address" type="hidden" [value]="addrDetailsList"/>
                                                                        <div class="row pt-1">
                                                                        <div class="col-md-6 text-danger">
                                                                        
                                                                            <p>
                                                                            Please Enter Atleast one Address Details
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-md-6 text-danger">
                                                                            <p class="text-right">!</p>
                                                                        </div>
                                                                        </div>
                                                                    </div>  -->
                                                                        <div class="col-md-12">
                                                                            <div class="form-group custom-control custom-checkbox">
                                                                                <input type="checkbox" class="custom-control-input"
                                                                                (change)="onHazardousChange($event)"
                                                                                    id="IsHazardous" formControlName="IsHazardous">
                                                                                <label class="custom-control-label"
                                                                                    for="IsHazardous">Hazardous Cargo</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>UN Number & Proper Shipping Name</label>
                                                                            <ng-select id="UN_Id"
                                                                                formControlName="UN_Id"
                                                                                [items]="unNumbers" 
                                                                                bindLabel="label"
                                                                                bindValue="value" 
                                                                                (change)="onChangeUN_Number($event)"
                                                                                placeholder="Automobile, Marbles, Pharma, etc">
                                                                                <ng-option></ng-option>
                                                                            </ng-select>
                                                                            <input type="hidden" formControlName="UN_Name"/>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <ng-select id="serviceType"
                                                                    formControlName="ServiceTypeId"
                                                                    [items]="serviceTypes" 
                                                                    bindLabel="jobtypeName"
                                                                    bindValue="jobtypeId" 
                                                                    (change)="onChangeServiceType($event)"
                                                                    placeholder="FCL, Air, Etc">
                                                                    <ng-option></ng-option>
                                                                </ng-select>
                                                                <input type="hidden" formControlName="ServiceType"/> -->
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>Remarks for Hazardous Cargo</label>
                                                                            <input id="RemarksHazardousCargo" type="text"
                                                                                class="form-control"
                                                                                formControlName="RemarksHazardousCargo"
                                                                                placeholder="Haz Notes" />
                                                                        </div>
                                                                    </div>                  

                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="rounded-corner-accordian  mt-2">
                                <section id="accordion">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div id="accordionWrapa1" role="tablist" aria-multiselectable="true">
                                                <div class="collapse-icon p-1">
                                                    <div class="collapse-default">
                                                        <ngb-accordion [destroyOnHide]="false" [closeOthers]="true">
                                                            <ngb-panel id="panelBorderBottom4">
                                                                <ng-template ngbPanelTitle>
                                                                    <div class="panel-title pb-2">
                                                                        <div
                                                                            class="d-flex justify-content-start align-items-center">
                                                                            <span class="icon-left">
                                                                                <i class="feather icon-file"
                                                                                    aria-hidden="true"></i>
                                                                            </span>
                                                                            <span class="padd-add">Address</span>
                                                                        </div>
                                                                        <div class="d-flex ">
                                                                            <!-- <button
                                                                type="button"
                                                                class="btn btn-outline-success mr-2 f-adrress-btn"
                                                                
                                                                rippleEffect
                                                                (click)="toggleSideNav()"
                                                              >
                                                                Add Address
                                                              </button>
                                                          <button type="button" class="btn btn-outline-success mr-2 f-adrress-btn"
                                                            rippleEffect >
                                                            Add Address
                                                          </button> -->
                                                                            <span class="icon-right">
{{companyName}}{{leadName}}
                                                                                <i class="feather icon-plus-square" (click)="openEnquiryAddressesModal(enquiryId,companyName||leadName)"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>

                                                                <ng-template ngbPanelContent>
                                                                    <div *ngIf="enquiryaddrDetailsList && enquiryaddrDetailsList.length === 0">
                                                                        <input formControlName="address" type="hidden" [value]="enquiryaddrDetailsList"/>
                                                                        <div class="row pt-1">
                                                                            <div class="col-md-6 text-danger">
                                                                            
                                                                                <p>
                                                                                Please Enter Atleast one Address Details
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-md-6 text-danger">
                                                                                <p class="text-right">!</p>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                    <div *ngIf="enquiryaddrDetailsList">
                                                                        <div *ngIf="enquiryaddrDetailsList && enquiryaddrDetailsList.length > 0" class="table-responsive">
                                                                       
                                                                         
                                                                        <div class="mb-1 cursor-pointer" *ngFor="let detail of enquiryaddrDetailsList"
                                                                            >
                                                                            <div class="row">
                                                                                <div class="col-md-9" (click)="openModalEnqArressByData(detail)">
                                                                                    <div class="badge badge-light-success"><b>{{ detail.addressTypeValue||detail.addressTypeNick||detail.addressTypeName }} Address</b>
                                                                                    </div>
                                                                                    <div class="pt-1">{{ detail.company|| detail.companyName}}, {{ detail.addressLine1 }},{{ detail.addressLine2
                                                                                        }}, {{ detail.city||detail.cityName }}-{{ detail.zipCode|| detail.zipcode}},{{ detail.country }},{{
                                                                                        detail.state ||detail.stateName}}</div>
                                                                                </div>
                                                                                <div class="col-md-3 text-right">
                                                                        
                                                                                    <!-- <div class="badge badge-light-success mr-1">{{ detail.addressTypeValue||detail.addressTypeNick }}</div> -->
                                                                                    <span class="mr-1" (click)="copyAddress($event, detail)"><i class="feather icon-copy"></i></span>
<!--                                                                         
                                                                                </div>
                                                                                <div class="col-md-2 text-right"> -->
                                                                                    <span (click)="deleteAddressId(detail.enquiryAddressId)" href="javascript:void(0)" >
                                                                                        <i data-feather="trash" class="mr-1 text-danger"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                                <!-- <div class="row">
                                                                                    <div class="col-md-2 text-right">
                                                                                        <a (click)="deleteAddressId(detail.enquiryAddressId)" href="javascript:void(0)" ngbDropdownItem>
                                                                                            <i data-feather="trash" class="mr-1 text-danger"></i>
                                                                                        </a>
                                                                                    </div>
                                                                          
                                                                                </div> -->
                                                                                <hr>
                                                                      </div>

                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ngb-accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="hidden" formControlName="CreatedBy" value="userId"/>
                <input type="hidden" formControlName="ModifiedBy" value="userId"/>
                <input type="hidden" formControlName="DeletedBy" value="userId"/>

            </form>
        </div>
    </div>
</section>