<div class="scroll-container">
    <div class="d-flex mt-1">
        <div class="col-md-4 p-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-center">
                    <div class="d-flex flex-column align-items-center">
                        <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <i data-feather="briefcase" class="avatar-icon font-medium-3"></i>
                            </div>
                          </div>
                        <div class="title-container mb-1 mt-1">
                            <h4 class="card-title"><b>{{ totalSales?.title}}</b></h4>
                        </div>
                    </div>
                    <div class="mt-1">
                        <p class="badge badge-light-success count">{{totalSales?.shipmentCount}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-center">
                    <div class="d-flex flex-column align-items-center">
                        <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <i data-feather="dollar-sign" class="avatar-icon font-medium-3"></i>
                            </div>
                          </div>
                        <div class="title-container mb-1 mt-1 d-flex d-flex flex-column">
                            <h4 class="card-title"><b>{{ realizedRevenue?.title }}</b></h4><small class="text-center">INR</small>
                            
                        </div>                        
                    </div>
                    <div class="mt-0">
                        <p class="badge badge-light-success count">{{realizedRevenue?.totalRealizedRevenue | number:'1.2-2'}}</p>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="col-md-4 pr-0">
            <div class="totalsale rounded-corners">
                <div class=" d-flex flex-column align-items-center">
                    <div class="d-flex flex-column align-items-center">
                        <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <i data-feather="dollar-sign" class="avatar-icon font-medium-3"></i>
                            </div>
                          </div>
                        <div class="title-container mb-1 mt-1 d-flex d-flex flex-column">
                            <h4 class="mt-1 title">
                                    <b>{{ mainTitle }}</b><span class="sub-title">{{ subTitle }}</span>
                            </h4>
                            <small class="text-center">INR</small>
                        </div>
                    </div>
                    <div class="mt-0">
                        <!-- <p class="value">INR</p> -->
                        <div class=" badge badge-light-success count">{{sumOfRealizedRevenueLast60Days?.totalRealizedRevenueLast60Days | number:'1.2-2'}}</div>
                        <!-- <p class="font-large-1 font-weight-bold">{{sumOfRealizedRevenueLast60Days?.totalRealizedRevenueLast60Days | number:'1.2-2'}}</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="scroll-container">
  <div class="card-container d-flex flex-nowrap gap-3">
    <div class="card totalsale rounded-corners shadow-sm gradient-bg-blue">
      <div class="card-body d-flex flex-column align-items-start text-white">
        <div class="badge bg-light text-dark py-1 px-3 rounded-pill mb-2">
          Sales Overview
        </div>
        <h5 class="card-title font-weight-bold">{{ totalSales?.title }}</h5>
        <p class="value h4 mt-auto">{{ totalSales?.shipmentCount }}</p>
      </div>
    </div>

    <div class="card realized-revenue rounded-corners shadow-sm gradient-bg-green">
      <div class="card-body d-flex flex-column align-items-start text-white">
        <h5 class="card-title font-weight-bold">{{ realizedRevenue?.title }}</h5>
        <p class="value h4 mt-auto">INR {{ realizedRevenue?.totalRealizedRevenue | number:'1.2-2' }}</p>
      </div>
    </div>

    <div class="card main-revenue rounded-corners shadow-sm gradient-bg-purple">
      <div class="card-body d-flex flex-column align-items-start text-white">
        <h5 class="card-title font-weight-bold">
          {{ mainTitle }} <span class="sub-title text-light">{{ subTitle }}</span>
        </h5>
        <p class="value h4 mt-auto">INR {{ sumOfRealizedRevenueLast60Days?.totalRealizedRevenueLast60Days | number:'1.2-2' }}</p>
      </div>
    </div>
  </div>
</div> -->
