<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section class="invoice-edit-wrapper">
        <div class="row invoice-edit">
          <!-- Invoice Edit Left starts -->
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card invoice-preview-card">
              <!-- Header starts -->
              <div class="card-body invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                 
                  <div>
                    
                    <p class="card-text mb-25">GLOBAL LOGISTICS SOLUTIONS PVT LTD.</p>
                    <p class="card-text mb-25">MUMBAI TO NHAVA SHEVA</p>
                    <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                    <p class="card-text mb-0">+1 (123) 456 7891, +44 (876) 543 2198</p>
                  </div>
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">Enquiry</h4>
                      <div class="input-group input-group-merge invoice-edit-input-group disabled">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i data-feather="hash"></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control invoice-edit-input"
                          placeholder="53634"
                          disabled
                          [(ngModel)]="apiData.id"
                        />
                      </div>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="title">Received:</span>
                      <ng2-flatpickr [config]="DateRangeOptions" name="DateRange"></ng2-flatpickr>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title">Submitted:</span>
                      <ng2-flatpickr [config]="DateRangeOptions" name="DateRange"></ng2-flatpickr>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Header ends -->
  
              <hr class="invoice-spacing" />

              <div class="invoice-padding mb-1">
                <h4 class="rate-header p-1">Buying Rates</h4>
              </div>
  
              <!-- Product Details starts -->
              <div class="card-body invoice-padding invoice-product-details">
               
                <form class="source-item">
                  <div data-repeater-list="group-a">
                    <div class="row w-100 ">
                        <div class="col-lg-3 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2"><p class="card-text col-title mb-md-50 mb-0">Charge Item</p></div>
                        <div class="col-lg-2 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2"> <p class="card-text col-title mb-md-50 mb-0">Qty</p></div>
                        <div class="col-lg-2 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2"> <p class="card-text col-title mb-md-50 mb-0">Rate</p></div>
                        <div class="col-lg-2 col-12 my-lg-0 my-2"><p class="card-text col-title mb-md-2 mb-0">ExRate</p></div>
                        <div class="col-lg-2 col-12 my-lg-0 my-2"> <p class="card-text col-title mb-md-2 mb-0">Tax</p></div>
                        <div class="col-lg-1 col-12 my-lg-0 my-2"><p class="card-text col-title mb-md-50 mb-0">Total</p></div>
                    </div>
                    <div class="repeater-wrapper" data-repeater-item *ngFor="let item of items; let i = index" @heightIn>
                      <div class="row">
                        <div class="col-12 d-flex product-details-border position-relative pr-0">
                          <div class="row w-100 pr-lg-0 pr-1 py-2">
                            <div class="col-lg-3 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                              <ng-select
                                [items]="['Ocean Freight', 'Air Freight']"
                                bindLabel="label"
                                [(ngModel)]="item.chargeItem"
                                placeholder="Select Charge Item"
                                class="item-details"
                              >
                              </ng-select>
                            </div>
                            <!-- <div class="col-lg-2 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                <div class="input-group">
                                  <input type="number"  id="quantity" class="form-control">
                                  <select id="unit" class="form-control unit-select">
                                    <option value="20GP">20GP</option>
                                    <option value="30GP">30GP</option>
                                    <option value="40GP">40GP</option>
                                    <option value="50GP">50GP</option>
                                  </select>
                                </div>
                              </div> -->
                              <div class="col-lg-2 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                <div class="input-group">
                                  <input type="number" id="quantity" class="form-control" placeholder="Qty">
                                  <ng-select 
                                    [items]="['20GP', '30GP', '40GP', '50GP']" 
                                    bindLabel="label"
                                    placeholder="Select Unit"
                                    class="unit-select">
                                  </ng-select>
                                </div>
                              </div>
                              
                            <div class="col-lg-2 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                <!-- <p class="card-text col-title mb-md-50 mb-0">Rate</p> -->
                                <div class="input-group">
                                  <input type="number"  id="quantity" class="form-control">
                                  <ng-select
                                  [items]="['INR (₹)', 'USD ($)', 'EUR (€)', 'GBP (£)']"
                                  bindLabel="label"
                                  [(ngModel)]="item.currency"
                                  placeholder="Select Currency"
                                  class="unit-select"
                                >
                                </ng-select>
                                </div>
                              </div>
                            <div class="col-lg-2 col-12 my-lg-0 my-2">
                              <!-- <p class="card-text col-title mb-md-2 mb-0">ExRate</p> -->
                              <input
                                type="number"
                                class="form-control"
                                id="itemQuantity{{ i }}"
                                name="itemQuantity{{ i }}"
                                [(ngModel)]="item.itemQuantity"
                                placeholder="1"
                              />
                            </div>
                            <div class="col-lg-2 col-12 my-lg-0 my-2">
                              <ng-select
                                [items]="[0, 5, 10, 15, 20]"
                                bindLabel="label"
                                [(ngModel)]="item.tax"
                                placeholder="Select Tax %"
                              >
                              </ng-select>
                            </div>
                            <div class="col-lg-1 col-12 mt-lg-0 mt-2">
                              <!-- <p class="card-text col-title mb-md-50 mb-0">Total</p> -->
                              <p class="card-text mb-0">$24.00</p>
                            </div>
                          </div>
                          <div
                            class="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-50 px-25"
                          >
                            <button (click)="deleteItem(i)" class="btn p-0">
                              <i data-feather="x" [size]="18" class="cursor-pointer"></i>
                            </button>
                            
                            <div ngbDropdown class="dropdown" #discountDropdown="ngbDropdown">
                              <button
                                class="cursor-pointer more-options-dropdown mr-0 btn p-0 hide-arrow"
                                id="dropdownMenuButton"
                                role="button"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                ngbDropdownToggle
                              >
                                <i data-feather="more-vertical"></i>
                              </button>
                            
                              <div
                                ngbDropdownMenu
                                class="dropdown-menu dropdown-menu-right item-options-menu p-0"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a ngbDropdownItem>
                                    <i data-feather="edit" class="mr-1"></i>Edit 
                                </a>
                            
                                <div class="dropdown-divider"></div>
                                <a href="javascript:void(0)" ngbDropdownItem >
                                    <i data-feather="trash" class="mr-1"></i>Delete 
                                </a>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12 px-0">
                      <button type="button" class="btn btn-primary btn-sm btn-add-new" (click)="addItem()" rippleEffect>
                        <i data-feather="plus" class="mr-25"></i>
                        <span class="align-middle">Add Item</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Product Details ends -->
  
              <!-- Invoice Total starts -->
              <div class="card-body invoice-padding">
                <div class="row invoice-sales-total-wrapper">
                  <div class="col-md-6 order-md-1 order-2 mt-md-0 mt-3">
                    <div class="d-flex align-items-center mb-1">
                      <label for="salesperson" class="form-label">Salesperson:</label>
                      <input type="text" class="form-control ml-50" id="salesperson" placeholder="Edward Crowley" />                
                    </div>
                    <textarea class="form-control" rows="1" id="note">
                        Thanks for your business</textarea>
                  </div>
                  
                  <div class="col-md-6 d-flex justify-content-end order-md-2 order-1">
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Subtotal:</p>
                        <p class="invoice-total-amount">$1800</p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Discount:</p>
                        <p class="invoice-total-amount">$28</p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Tax:</p>
                        <p class="invoice-total-amount">21%</p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Total:</p>
                        <p class="invoice-total-amount">$1690</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Invoice Total ends -->
  
              <hr class="invoice-spacing mt-0" />
  
              <div class="card-body invoice-padding py-0">
                <!-- Invoice Note starts -->
                <div class="row">
                  <div class="col-12">
                    <div class="form-group mb-2">
                      <label for="note" class="form-label font-weight-bold">Note:</label>
                      <textarea class="form-control" rows="2" id="note">
  It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!</textarea
                      >
                    </div>
                  </div>
                </div>
                <!-- Invoice Note ends -->
              </div>
            </div>
          </div>
          <!-- Invoice Edit Left ends -->
  
         
        </div>
      </section>
    </div>
  
  </div>
  