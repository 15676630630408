<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section class="enquiry-list-wrapper">
            <div class="card">
                <div class="row">
                    <div class="col-md-6 col-12">
                        <div class="d-flex justify-content-between align-items-center m-1">
                            <div class="d-flex align-items-center">
                                <h4 class="d-flex align-items-center">Enquiry List</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2">
                        <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
                            <div class="col-10 col-md-6 pr-0">
                                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0">
                                    <input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control" placeholder="Search by Company Name" (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" />
                                </label>
                            </div>
                            
                            <div class="col-10 col-md-6 mt-2 ml-1 ml-md-0 mt-md-0">
                                <button class="btn btn-primary ml-2" routerLink="/enquiry-create" (click)="resetAllList()" rippleEffect><span [data-feather]="'plus'" class="mr-50"></span>New</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ngx-datatable
                class="bootstrap core-bootstrap"
              [rows]="enquiries"
              [rowHeight]="'auto'"
              [count]="totalRecords"
              [offset]="pageNumber - 1"
              [limit]="pageSize"
              [columnMode]="ColumnMode.force"
              [headerHeight]="120"
              [footerHeight]="30"
              [scrollbarH]="true"
              (page)='onPage($event)'
              >
                <!-- <ngx-datatable [rows]="enquiryRows" [rowHeight]="40" class="bootstrap core-bootstrap" [limit]="selectedOption" [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true"> -->
                    <ngx-datatable-column name="ID" prop="enquiryId" [width]="70">
                        <ng-template let-enquiryId="value" ngx-datatable-cell-template>
                            <a routerLink="/enquiry-preview/preview/{{ enquiryId }}" class="font-weight-bold">{{ enquiryId }}</a>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="CUSTOMER / LEAD" prop="companyName" [width]="200">
                        <ng-template let-value="value"let-row="row" ngx-datatable-cell-template>
                            <a [routerLink]="['/enquiry-preview/preview/', row['enquiryId']]" class="font-weight-bold ">
                                <div [innerHTML]="wrapText(value, 30)"></div>
                            </a>
                            <small class="d-block text-muted">auto Parts</small>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Service Type" prop="serviceType" [width]="120">
                        <ng-template let-serviceType="value" ngx-datatable-cell-template>
                            {{ serviceType }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Requirement" prop="requirement" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.totalContainers }} <span>×</span> {{ row.containerTypes}}

                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="enquiryStatus" [width]="150">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span [ngClass]="{
                                'badge-custom': true,
                                'badge-success badge-light-success': row.enquiryStatus === 'Closed',
                                'badge-warning badge-light-warning': row.enquiryStatus === 'Awaiting Approval',
                                'badge-info badge-light-info': row.enquiryStatus === 'Quotation Sent'
                              }">{{ row.enquiryStatus }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
                        <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="d-flex align-items-center col-actions">
                                <a class="mr-1" href="javascript:void(0);" container="body" placement="top" ngbTooltip="Send Mail">
                                    <i size="18" data-feather="mail"></i>
                                </a>
                                <a class="mr-1" [routerLink]="['/enquiry-preview', row.enquiryId]" container="body" placement="top" ngbTooltip="View Details">
                                    <i size="18" data-feather="eye"></i>
                                </a>
                                <div ngbDropdown container="body">
                                    <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow px-0" rippleEffect>
                                        <i data-feather="more-vertical" size="18" class="cursor-pointer"></i>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <a [routerLink]="['/enquiry/edit', row.enquiryId]" ngbDropdownItem>
                                            <i data-feather="edit" class="mr-1"></i>Edit 
                                        </a>
                                        <a href="javascript:void(0)" ngbDropdownItem (click)="onDeleteEnquiry(row.enquiryId)">
                                            <i data-feather="trash" class="mr-1"></i>Delete 
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                  <!-- Pagination Controls -->
        <ul class="pagination justify-content-end pr-1">
            <li class="page-item" [class.disabled]="pageNumber === 1">
              <a class="page-link" (click)="goToPreviousPage()">Previous</a>
            </li>
  
            <!-- Display page numbers with ellipsis -->
            <li *ngIf="pages[0] > 1" class="page-item">
              <a class="page-link" (click)="goToPage(1)">1</a>
            </li>
            <li *ngIf="pages[0] > 2" class="page-item disabled">
              <span class="page-link">...</span>
            </li>
  
            <li *ngFor="let page of pages" class="page-item" [class.active]="page === pageNumber">
              <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
            </li>
  
            <li *ngIf="pages[pages.length - 1] < totalPages - 1" class="page-item disabled">
              <span class="page-link">...</span>
            </li>
            <li *ngIf="pages[pages.length - 1] < totalPages" class="page-item">
              <a class="page-link" (click)="goToPage(totalPages)">{{ totalPages }}</a>
            </li>
  
            <li class="page-item" [class.disabled]="pageNumber === totalPages">
              <a class="page-link" (click)="goToNextPage()">Next</a>
            </li>
          </ul>
            </div>
        </section>

    </div>
</div>
