import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalidmsgpage',
  templateUrl: './invalidmsgpage.component.html',
  styleUrls: ['./invalidmsgpage.component.scss']
})
export class InvalidmsgpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
